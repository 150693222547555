import React, { FC } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./App.css";

export const ROLE_PLAYER = "Player";
export const ROLE_ADMIN = "Admin";

const AppHeader: FC<RouteComponentProps> = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <span className="navbar-brand">
        <img src="/logo_05_white.png" height="39" alt="SquareUp" />
      </span>
    </nav>
  );
};

export default withRouter(AppHeader);
