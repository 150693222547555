import React from "react";
import { ToastContainer } from "react-toastify";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import AppHeader from "./AppHeader";
import Eula from "./agreement/Eula";
import PrivacyPolicy from "./agreement/PrivacyPolicy";
import TermsOfUse from "./agreement/TermsOfUse";
import { StickyFooter } from "./layout/StickyFooter";
import DataDeletion from "./agreement/DataDeletion";
import AppStoreRedirects from "./AppStoreRedirects";

const AppRoot = () => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <main className="flex-fill">
        <Router>
          <Switch>
            <Route path="/legal">
              <AppHeader />
              <div className="container mt-3 mb-3">
                <Switch>
                  <Route path="/legal/eula">
                    <Eula />
                  </Route>
                  <Route path="/legal/terms-of-use">
                    <TermsOfUse />
                  </Route>
                  <Route path="/legal/privacy-policy">
                    <PrivacyPolicy />
                  </Route>
                  <Route path="/legal/data-deletion">
                    <DataDeletion />
                  </Route>
                  <Redirect to="/legal/eula" />
                </Switch>
              </div>
            </Route>
            <Route path="/redeem">
              <AppHeader />
              <div className="container mt-3 mb-3">
                <AppStoreRedirects />
              </div>
            </Route>
            <Route path="/redirect">
              <AppHeader />
              <div className="container mt-3 mb-3">
                <AppStoreRedirects />
              </div>
            </Route>
            <Redirect to="/redirect" />
          </Switch>
        </Router>
      </main>
      <StickyFooter />
      <ToastContainer hideProgressBar newestOnTop closeOnClick />
    </div>
  );
};

export default AppRoot;
