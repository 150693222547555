import { FC, useEffect } from "react";

const AppStoreRedirects: FC = () => {
  useEffect(() => {
    redirectToAppStore();
  }, []);

  const redirectToAppStore = () => {
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check if the user is on iOS
    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = "https://apps.apple.com/app/id6502373678";
    }
    // Check if the user is on Android
    else if (/android/i.test(userAgent)) {
      window.location.href =
        "intent://play.google.com/store/apps/details?id=com.getsquareup.squareup#Intent;scheme=https;package=com.android.vending;end";
    }
    // Fallback for other devices
    else {
      window.location.href = "https://www.getsquareup.com";
    }
  };

  return null;
};

export default AppStoreRedirects;
