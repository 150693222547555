import React from "react";

const Eula = () => {
  return (
    <div>
      <h2>IMPORTANT-READ CAREFULLY:</h2>
      <p>
        SquareUp. End-User License Agreement ("EULA") is a legal agreement
        between you (either an individual or a single entity) and SquareUp
        ("SquareUp") for the SquareUp software accompanying this EULA and any
        additional software downloaded and installed by the SquareUp software,
        which includes computer software and may include associated media,
        printed materials, and online or electronic documentation ("SOFTWARE").
        By exercising your rights to use the SOFTWARE, you agree to be bound by
        the terms of this EULA. If you do not agree to the terms of this EULA,
        do not install or use the SOFTWARE.
      </p>
      <h2>SOFTWARE LICENSE</h2>
      <p>
        The SOFTWARE is protected by copyright laws and international copyright
        treaties, as well as other intellectual property laws and treaties. The
        SOFTWARE is licensed, not sold.
      </p>
      <h2>GRANT OF LICENSE</h2>
      <p>
        This EULA grants you the following rights:Installation and Use. You may
        install and use a copy of the SOFTWARE on a single computer. Optionally
        you may store or install the SOFTWARE on a storage device, such as a
        network server, to run the SOFTWARE on your computer or your other
        computers; however the same license may not be shared or used
        concurrently on different computers or servers. Sufficient licenses must
        be acquired for each shared or concurrently used SOFTWARE.Reproduction
        and Distribution. You may not reproduce or distribute the SOFTWARE
        except to make backup copies, or to install as provided for above.
      </p>
      <h2>DESCRIPTION OF OTHER RIGHTS AND LIMITATIONS</h2>
      <p>
        Limitations on Reverse Engineering, Decompilation, and Disassembly. You
        may not reverse engineer, decompile, or disassemble the SOFTWARE, except
        and only to the extent that such activity is expressly permitted by
        applicable law notwithstanding this limitation.Software Transfer. You
        may permanently transfer all of your rights under this EULA, provided
        you retain no copies, you transfer all of the SOFTWARE, and the
        recipient agrees to the terms of this EULA.Termination. Without
        prejudice to any other rights, SquareUp may terminate this EULA if you
        fail to comply with the terms and conditions of this EULA. In such
        event, you must destroy all copies of the SOFTWARE and all component
        parts.
      </p>
      <h2>COPYRIGHT</h2>
      <p>
        All title and copyrights in and to the SOFTWARE (including but not
        limited to any images, text, and "applets" incorporated into the
        SOFTWARE), the accompanying printed materials, and any copies of the
        SOFTWARE are owned by SquareUp or its suppliers. The SOFTWARE is
        protected by copyright laws and international treaty provisions.
        Therefore, you must treat the SOFTWARE like any other copyrighted
        material.
      </p>
      <h2>LIMITED WARRANTY</h2>
      <p>
        NO WARRANTIES. SquareUp expressly disclaims any warranty for the
        SOFTWARE. The SOFTWARE and any related documentation are provided "as
        is" without warranty of any kind, either express or implied, including,
        without limitation, the implied warranties or merchantability, fitness
        for a particular purpose, and non-infringement. The entire risk arising
        out of use or performance of the SOFTWARE remains with you.NO LIABILITY
        FOR CONSEQUENTIAL DAMAGES. In no event shall SquareUp or its suppliers
        be liable for any damages whatsoever (including, without limitation,
        damages for loss of business profits, business interruption, loss of
        business information, or any other pecuniary loss) arising out of the
        use of or inability to use the SOFTWARE, even if SquareUp has been
        advised of the possibility of such damages. Because some
        states/jurisdictions do not allow the exclusion or limitation of
        liability for consequential or incidental damages, the above limitation
        may not apply to you.
      </p>
      <h2>MISCELLANEOUS</h2>
      <p>
        If you acquired the SOFTWARE in the United States, this EULA is governed
        by the laws of the State of Nebraska.If you acquired the SOFTWARE
        outside the United States, then local laws may apply.
      </p>
    </div>
  );
};

export default Eula;
