import React from "react";
import "./StickyFooter.css";

export const StickyFooter = () => {
  return (
    <footer className="footer">
      <div className="container">
        <span className="text-muted">
          Questions? Contact us at{" "}
          <a href="mailto:admin@getsquareup.com">admin@getsquareup.com</a>
        </span>
      </div>
    </footer>
  );
};
