import React from "react";

const DataDeletion = () => {
  return (
    <div>
      <h2>Data Deletion Request Instructions</h2>
      <p>
        In order to delete your data from the SquareUp platform, email us at{" "}
        <a href="mailto:admin@getsquareup.com">admin@getsquareup.com</a> with
        your SquareUp username. We will follow up with you on the status of your
        request.
      </p>
      <p>Thank you for using SquareUp!</p>
    </div>
  );
};

export default DataDeletion;
