import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import AppRoot from "./AppRoot";

Sentry.init({
  dsn: "https://c64aa68407a741c6b3dd88b2d660ba8d@sentry.io/1868327"
});

ReactDOM.render(<AppRoot />, document.getElementById("root"));
