import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <h2>Privacy Policy</h2>
      <p>
        <small>Last Update Posted: December 19, 2019</small>
      </p>
      <section>
        <h3>1. Introduction</h3>
        <p>
          1.1 SquareUp (“SquareUp, we, us”) recognizes that people who use
          SquareUp’s Service (“you” or “users”) value their privacy. This
          Privacy Policy details important information regarding the collection,
          use and disclosure of User information collected on SquareUp’s fantasy
          sports website located at getsquareup.com (the "Site"), the related
          mobile app, and any other features, tools, materials, or other
          services (including co-branded or affiliated services) offered from
          time to time by SquareUp or its affiliate companies (the “Service”).
          SquareUp provides this Privacy Policy to help you make an informed
          decision about whether to use or continue using the Service.
        </p>
        <p>
          1.2 This Privacy Policy should be read in conjunction with our Terms
          of Use. By accessing the Service, you are consenting to the
          information collection and use practices described in this Privacy
          Policy.
        </p>
        <p>
          1.3 Your use of the Service and any information you provide through
          the Service remains subject to the terms of this Privacy Policy and
          our Terms of Use, as each may be updated from time to time.
        </p>
        <p>
          1.4 Any questions, comments or complaints that you might have should
          be emailed to support@getsquareup.com or forwarded in writing to:
        </p>
        <p>SquareUp LLC</p>
        <p>571 South 182nd Street</p>
        <p>Elkhorn, NE 68022</p>
      </section>
      <section>
        <h3>2. Information We Collect</h3>
        <p>
          By using our Service and by submitting information to us through your
          use of our Service then this Privacy Policy will apply. You provide
          certain personal information to SquareUp when choosing to participate
          in the various activities on the Service such as registering for an
          account, participating in contests, posting messages, taking advantage
          of promotions, responding to surveys or subscribing to newsletters or
          other mailing lists. The personal information we collect from you
          generally may include:
        </p>
        <p>2.1.1 your name;</p>
        <p>2.1.2 email address;</p>
        <p>2.1.3 date of birth;</p>
        <p>2.1.4 PayPal or credit card billing information;</p>
        <p>2.1.5 contacts you choose to submit;</p>
        <p>2.1.6 profile photo;</p>
        <p>2.1.7 username;</p>
        <p>2.1.8 location;</p>
        <p>2.1.9 your preferences;</p>
        <p>2.1.10 submissions to various competitions and promotions;</p>
        <p>2.1.11 responses to surveys;</p>
        <p>2.1.12 communications sent to us by you;</p>
        <p>
          2.1.13 your subscriptions to newsletters and services offered by us;
          and
        </p>
        <p>
          2.1.14 any other information you submit to SquareUp when choosing to
          participate in various activities on the Service.
        </p>
        <p>
          2.2 In addition to the above, we may need to verify your identity in
          order for you to use some aspects of the Service. For purposes of
          verification, we may also collect the following personal information
          from you (for compliance reasons, provision of this information, when
          requested, is a mandatory condition of using our Service):
        </p>
        <p>2.2.1 passport information;</p>
        <p>2.2.2 driving license information;</p>
        <p>2.2.3 other identification documents;</p>
        <p>2.2.4 permanent and temporary address;</p>
        <p>2.2.5 tax-related information; and</p>
        <p>
          2.2.6 other information as may be required to verify you in accordance
          with applicable laws and regulations.
        </p>
        <p>
          2.3 When you use our mobile app, we also may collect mobile device
          information like operating system and hardware type, numbers or codes
          that are unique to your particular device (such as IDFA or an Android
          Ad ID), device information, default device language, the location of
          your device (at a GPS level), and app usage information. This data
          also may be linked to your other information, including your location
          data.
        </p>
        <p>
          2.4 In addition, if you choose to log in, access or otherwise connect
          to SquareUp, or contact SquareUp, through a social networking service
          (such as Facebook), we may collect your user ID and user name
          associated with that social networking service, as well as any
          information you make public using that social networking service. We
          may also collect information you have authorized the social networking
          service to share with us (such as your user ID, public profile
          information, email address, birthday, friends list, and pages you have
          "liked").
        </p>
        <p>
          2.5 Cookies Information: When you visit the getsquareup.com website,
          we may send one or more cookies - small files - to your computer or
          other device, which may enable us or others to uniquely identify your
          browser. SquareUp uses both session cookies and persistent cookies. A
          persistent cookie remains after you close your browser. Persistent
          cookies may be used by your browser on subsequent visits to the site.
          Persistent cookies can be removed by following your web browser help
          file directions. Session cookies are temporary and typically disappear
          after you close your browser. You may be able to reset your web
          browser to refuse all cookies or to indicate when a cookie is being
          sent. However, some features of the Site or Service may not function
          properly if the ability to accept cookies is disabled.
        </p>
        <p>
          2.6 Log File Information: When you use our Service, our servers may
          automatically record certain information that your device sends
          whenever you visit any website and use certain apps. These server logs
          may include information such as your web or app request, Internet
          Protocol ("IP") address, browser type, browser language,
          referring/exit pages and URLs, platform type, number of clicks, domain
          names, landing pages, pages viewed and the order of those pages, the
          amount of time spent on particular pages, the date and time of your
          request, and one or more cookies that may uniquely identify your
          browser.
        </p>
        <p>
          2.7 Clear GIFs Information: When you use our Service, we may employ
          technology such as "clear GIFs" (a.k.a. Web Beacons) which are used to
          track the online usage patterns of our users. In addition, we may also
          use clear GIFs in HTML-based emails sent to our users to track which
          emails are opened by specific users.
        </p>
        <p>
          2.8 SquareUp may also collect information about you from third
          parties, such as marketing partners, identity verification services,
          anti-fraud services and other service providers.
        </p>
      </section>
      <section>
        <h3>3. The Way SquareUp Uses Your Personal Information</h3>
        <p>
          3.1 We use your personal information to operate, maintain, and provide
          to you the features and functionality of the Service, including but
          not limited to the following:
        </p>
        <p>
          3.1.1 providing you with our products and services, including our
          games;
        </p>
        <p>3.1.2 processing and responding to enquiries;</p>
        <p>3.1.3 personalizing your use of the Service,</p>
        <p>
          3.1.4 alerting you to new features, special events, products and
          services, or certain third-party products or services in which we
          think you will be interested;
        </p>
        <p>
          3.1.5 enforcing the legal terms that govern your use of the Service;
          and
        </p>
        <p>
          3.1.6 investigating and protecting the integrity of SquareUp’s
          contests.
        </p>
        <p>
          3.2 We may use your information (both personal and non-personal
          information) to send you marketing and advertising content, including
          sending you advertising through multiple channels, such as direct
          mail, email, push notifications and display media. We may send you
          advertising or content regarding our products and services, or
          products and services that we market on behalf of another company,
          such as a sports team, a sports venue, or other entity.
        </p>
        <p>3.3 We may use your information to communicate with you about:</p>
        <p>
          3.3.1 our products and services in which you may be interested
          provided that you have not requested otherwise;
        </p>
        <p>
          3.3.2 newsletters and information for which you have signed up; and
        </p>
        <p>
          3.3.3 non-marketing or administrative purposes (such as notifying you
          of major changes to the Service or for customer service purposes).
        </p>
        <p>
          3.3 We use your information to improve the quality and design of our
          Service and to create new features, promotions, functionality, and
          services such as by storing, tracking,and analyzing user preferences
          and trends.
        </p>
        <p>
          3.4 We also use cookies, clear gifs, log file information, and mobile
          app information such as device identifiers for purposes such as (a)
          remembering information so that you will not have to re-enter it
          during your visit or the next time you visit our Service; (b)
          providing custom, personalized content and information; (c) monitoring
          the effectiveness of our marketing campaigns; (d) monitoring aggregate
          metrics such as total number of visitors, pages viewed, etc.; and (e)
          tracking your entries, submissions, and status in promotions,
          sweepstakes, and contests. You can learn more about how these
          technologies are used in the Section below, Third Party Advertising
          and Analytics.
        </p>
        <p>
          3.5 We may anonymize, de-identify, or aggregate your information for
          any legitimate business purposes, including for reporting and
          research, or advertising. Such anonymized and/or aggregated data does
          not personally identify you. Sometimes, this information may be used
          to target more relevant content or offers to you across different
          devices – for instance, we may provide you with offers and content on
          your mobile device, based on your web-based activity.
        </p>
        <p>
          3.6 We will retain personal information as long as necessary to
          fulfill these purposes unless the law requires us to keep it for a
          longer period of time. To provide security and business continuity for
          the activities described here, we make backups of certain data, which
          we may retain for longer than the original data.
        </p>
      </section>
      <section>
        <h3>4. When SquareUp Discloses Information</h3>
        <p>
          4.1 We may disclose the information we collect or receive from you to
          our subsidiaries, affiliated companies, agents, or other businesses,
          or service providers who process your information on our behalf in
          order to provide or operate the Service. For instance, these entities
          may have access to your information for purposes including (but not
          limited to) billing and accounting, hosting, marketing, promotional
          and email services, customer and technical support, and for the
          purpose of accepting deposits and paying withdrawals and processing
          such information on our behalf, and in each case, may use the
          information to make improvements to the services they provide to us.
          Our agreements with these service providers limit the kinds of
          information they can use or process and require them to use reasonable
          efforts to keep your personal information secure.
        </p>
        <p>
          4.2 When you choose to share information with social media services
          about your activities on SquareUp by, for example, connecting your
          social media account to our Service, then SquareUp may share your
          personal information and information about your use of the Service
          with such social media sites. Please make sure to read the terms and
          privacy statements of such sites prior to connecting to our Service in
          this way.
        </p>
        <p>
          4.3 In the event that you win a prize we may use your personal
          information (such as your name and photo) in connection with publicity
          purposes.
        </p>
        <p>
          4.4 In the event that you win a promotion run by us we may be required
          to either publishor make available upon request your name and county
          in accordance with applicable legislation.
        </p>
        <p>
          4.5 We may publish a profile page that includes your username, the
          date you became a member, your stats from previous contests and, if
          you uploaded one, your profile picture. Where you post personal
          information about yourself through our Service to public areas of our
          Service, you acknowledge and agree that this personal information will
          be publicly available.
        </p>
        <p>
          4.6 When you participate in a contest, we may publish your username on
          a list of the contest participants, along with a link to your profile
          page.
        </p>
        <p>
          4.7 Where you have provided your consent, we may share your personal
          information with selected third parties for their commercial or
          marketing use in conjunction with your relationship with SquareUp or
          as part of a specific program or feature.
        </p>
        <p>
          4.8 In addition, we may disclose to third parties various types of
          your information for targeted online or mobile advertising, as further
          described in the Section titled “Third Party Advertising and
          Analytics.”
        </p>
        <p>
          4.9 We also may share personal information in other situations with
          your consent.
        </p>
        <p>
          4.10 SquareUp also reserves the right to disclose personal information
          that SquareUp believes, in good faith, is appropriate or necessary to
          enforce our Terms of Use, take precautions against liability or harm,
          to investigate and respond to third-party claims or allegations, to
          respond to court orders or official requests, to comply with state and
          federal regulations, including but not limited to sharing with
          appropriate tax authorities, to protect the security or integrity of
          our Service, to report violations of SquareUp’s eligibility rules to
          third parties, including employers of individuals affiliated with
          other daily fantasy sites, and to protect the rights, property, or
          safety of SquareUp, our users or others.
        </p>
        <p>
          4.11 In the event that SquareUp is involved in a merger, acquisition,
          sale, bankruptcy, insolvency, reorganization, receivership, assignment
          for the benefit of creditors, or the application of laws or equitable
          principles affecting creditors' rights generally, or other change of
          control, there may be a disclosure of your personal information to
          another entity related to such event.
        </p>
        <p>
          4.12 Recipients of the data disclosures described in this Privacy
          Policy are located in the United States and elsewhere in the world,
          including where privacy laws may not provide as much protection as
          your country.
        </p>
      </section>
      <section>
        <h3>5. Legal Bases for Processing Personal Data</h3>
        <p>
          5.1 Some laws require companies to tell you about the legal grounds
          they rely on to process your personal information. To the extent those
          laws apply, we process your personal information:
        </p>
        <p>
          5.1.1 Legitimate interests: In many cases, we handle personal data on
          the ground that it furthers our legitimate interests’ in commercial
          activities such as the following in ways that are not overridden by
          the interests or fundamental rights and freedoms of the affected
          individuals:
        </p>
        <p>(a) Customer service</p>
        <p>(b) Marketing</p>
        <p>(c) Protecting our players, personnel and property</p>
        <p>(d) Analyzing and improving our business</p>
        <p>(e) Processing job applications</p>
        <p>(f) Managing legal issues</p>
        <p>
          5.1.2 We may also process personal data for the legitimate interests
          of our affiliates or business partners, such as to offer special
          events or contests or assist with marketing.
        </p>
        <p>
          5.1.3 Processing for the contract: Some of our processing of personal
          data is to meet our contractual obligations with our players.
        </p>
        <p>
          5.1.4 Consent: Where required by law, and in some other cases, we
          handle personal data on the basis of your implied or express consent.
        </p>
        <p>
          5.1.5 Legal compliance: We need to use and disclose personal data in
          certain ways to comply with our legal obligations.
        </p>
      </section>
      <section>
        <h3>6. Your Choices</h3>
        <p>
          6.1 SquareUp will process your personal information in accordance with
          this Privacy Policy, and as part of that SquareUp provides you with
          certain choices about how we process your personal information. These
          choices are set out below.
        </p>
        <p>
          6.2 You can access and update certain personal information SquareUp
          holds about you at any time by logging into your account via the
          Service. You can update your marketing preferences and whether or not
          you would like us to send you newsletters in your account. You can
          also unsubscribe to marketing emails and newsletters by using the
          'unsubscribe' feature in the communication.
        </p>
        <p>
          6.3 We may occasionally send display media to you, in a targeted way,
          whether through web or mobile browsers or mobile applications. You may
          opt out of many third parties that support and send this type of
          targeting advertising by going to www.aboutads.info, and you may learn
          more about this type of advertising in the below Section titled “Third
          Party Advertising and Analytics.” You may opt out of tracking for
          mobile advertising through the settings on most smartphones, and you
          may learn more about these settings through those mobile device
          platforms, i.e., Google (here) and Apple (here). (Please note that
          these companies may change either the way these settings operate, the
          content or the availability of these pages.)
        </p>
        <p>
          6.4 We may occasionally send you push notifications to your mobile
          device. You can turn off certain push notifications in the account
          settings of your SquareUp mobile app.You can also turn off push
          notifications specific to our Service in the settings on your iOS
          device, or through the application manager in your Android device.
        </p>
        <p>
          6.5 Subject to some important legal limitations, users in Canada can
          contact us as described below to make additional requests, such as to
          request access to, correction or deletion of certain personal
          information we store; to receive a copy of certain personal
          information they provided to us in portable form or to request that we
          share it with a third party; to request restrictions on the use of
          their personal information; to object to our use of their personal
          information; or to withdraw consent to use their personal information
          (which will not affect the legality of any processing that happened
          before the request takes effect).
        </p>
        <p>
          6.6 For example, Canadian users can contact us to opt out of all
          processing of their personal information for direct marketing purposes
          (in addition to the unsubscribe option described here).
        </p>
        <p>
          6.7 Can reach us as described below with any other concerns, requests
          or complaints regarding our data collection, data use or data
          transfer. You also have a right to file a privacy complaint with the
          relevant privacy regulator, but we respectfully invite you to allow us
          to try to resolve the matter directly. We will attempt to answer your
          questions and satisfy your concerns in a timely and complete manner.
        </p>
        <h3>7. Advertising And Analytics</h3>
        <p>
          7.1 We work with a number of companies that assist in marketing our
          services to you on third party websites. These companies may collect
          information about online activities conducted on a particular
          computer, browser or device over time and across third-party websites
          or online services for the purpose of delivering advertising that is
          likely to be of greater interest to you, including about our Services,
          on sites and apps of third parties. Also, certain ads appearing on our
          Service are delivered by our advertising partners. Our advertising
          partners may use cookies and other similar technologies, often in
          conjunction with unique (anonymous) cookie identifiers, to collect
          data about your activities and interests, including the technologies
          described above. Some of these partners may use mobile device
          identifiers, such as Apple IDFAs and Google/Android Advertising IDs.
          Doing this allows the ad companies to recognize your computer or
          device each time they send you an online or mobile advertisement. In
          this way, the ad companies may compile information about where you, or
          others who are using your computer or device, saw their advertisements
          and measure your interaction with each ad. This information allows ad
          companies to deliver targeted advertisements that may be of increased
          interest to you, both on our Service and elsewhere online. Just as
          these technologies may be used to target ads on our websites and apps,
          we, or our advertising partners, may use these same technologies and
          data points (e.g., mobile identifiers, cookie identifiers,
          location-based data), through our Service or through other services,
          to target advertising (for ourselves or other companies) on other
          sites or mobile apps. Sometimes, these identifiers may be derived from
          a hashed or encrypted version of personal information such as your
          email address. We may use this information to measure the performance
          of our advertising as well, for instance, to evaluate which ads or
          content our users prefer, or which are most effective. In addition,
          sometimes we or an advertising partner may tailor advertising to you
          across different devices.
        </p>
        <p>
          7.2 To learn more and to opt out of the collection of data on our
          website by third parties (including those described above) for
          interest-based advertising purposes, please visit{" "}
          <a href="https://www.aboutads.info/choices">
            www.aboutads.info/choices
          </a>{" "}
          or{" "}
          <a href="https://www.youronlinechoices.com">
            www.youronlinechoices.com
          </a>
          . Note that because this opt out method may be cookie-based, you will
          need to opt out again if you update or change your browser, or delete
          your cookies. You can also control interest-based advertising on apps
          on your mobile device with the AppChoices app, available for iOS and
          Android. Note that opting out will not affect the quantity of ads you
          see online but likely will cause the ads to be less relevant to you.
        </p>
        <p>
          7.3 We also that we also work with third-party analytics companies to
          collect data on ourService through cookies and other automated means.
          In some cases, the third parties mentioned in this section may
          maintain the information they collect in personally identifiable form.
          These services allow us to view a variety of reports about how
          visitors interact with the Services so we can improve our website and
          understand how people find and navigate it.
        </p>
        <h3>8. Information Security</h3>
        <p>
          8.1 We are committed to protecting the security of your personal
          information. We use a variety of security technologies and procedures
          to help protect your personal information from unauthorized access,
          use, or disclosure.
        </p>
        <p>
          8.2 Whilst neither we, nor any other organization, can guarantee the
          security of information processed online, we do have appropriate
          security measures in place to protect your personal information. For
          example, we store the personal information you provide on computer
          systems with limited access that are located in facilities to which
          access is limited.
        </p>
        <p>
          8.3 To further protect your privacy and security, we reserve the right
          to take reasonable steps (such as requesting your password) to verify
          your identity before granting you profile access or making
          corrections.
        </p>
        <p>
          8.4 Additionally, you should ensure your password is kept secret at
          all times. You will be responsible for maintaining the secrecy of your
          password and account information.
        </p>
        <h3>9. State-Specific Privacy Rights</h3>
        <p>
          9.1 Shine the Light Disclosures. If you reside in California, you may
          request certain general information regarding our disclosure of
          personal information during the preceding year to third parties for
          their direct marketing purposes. To make such a request, please write
          to us at the following address:
        </p>
        <p>SquareUp LLC</p>
        <p>571 South 182nd Street</p>
        <p>Elkhorn NE, 68022</p>
        <p>
          9.2 California Consumer Privacy Act. If you are a California resident,
          California law requires us to provide you with some additional
          information regarding how we collect, use, and share your “personal
          information” (as defined in the California Consumer Privacy Act
          (“CCPA”)).
        </p>
        <p>
          <strong>Categories of personal information we collect.</strong>{" "}
          Throughout this Privacy Policy, we discuss in detail the specific
          pieces of personal information we collect from and about users. Under
          the CCPA, we are also required to provide you with the “categories” of
          personal information we collect. The categories we may collect are:
          identifiers (such as name, address, email address, driver’s license);
          commercial information (such as deposit or contest entry data);
          financial data (such as payment information); internet or other
          network or device activity (such as browsing history or Services
          usage); geolocation information (e.g., your city and state based on IP
          address); professional or employment related data (e.g., as part of
          your profile); audio information (e.g., if you participate in a
          customer support call and do not opt out of call recording); in
          certain circumstances, information used to manage potential fraud or
          legal risk (such as employment status and criminal history); inference
          data about you; and other information that identifies or can be
          reasonably associated with you.
        </p>
        <p>
          <strong>
            How we use and disclose these categories of personal information.
          </strong>{" "}
          We use and disclose the categories of personal information we collect
          from and about you consistent with the various business purposes we
          discuss throughout this Policy, and how you engage with the Service.
          Please see the relevant section(s) above for more information.
        </p>
        <p>9.3 Your California Privacy Rights</p>
        <p>
          CCPA Rights Disclosure. If you are a California resident, the CCPA
          allows you to make certain requests about your personal information.
          Specifically, the CCPA allows you to request:
        </p>
        <p>
          <ul>
            <li>
              Information about the categories of personal information we
              collect or disclose about you; the categories of sources of such
              information; the business or commercial purpose for collecting
              your personal information; and the categories of third parties
              with whom we share/disclose personal information. Such information
              is also set forth in this Privacy Policy.
            </li>
            <li>
              Provide access to and/or a copy of certain information we hold
              about you.
            </li>
            <li>Delete certain information we have about you.</li>
            <li>
              Provide you with information about the financial incentives that
              we offer to you,if any.
            </li>
          </ul>
        </p>
        <p>
          The CCPA further provides you with the right to not be discriminated
          against (as provided for in applicable law) for exercising your
          rights.
        </p>
        <p>
          We will consider all requests and provide our response within the time
          period stated by applicable law. Please note, however, that certain
          information may be exempt from such requests in some circumstances,
          which may include if we need to keep processing your information or to
          comply with a legal obligation. We will let you know where this is the
          case or if certain rights don’t apply in your country or state of
          residence. We also will take reasonable steps to verify your identity
          before responding to a request, which might include, at a minimum,
          depending on the sensitivity of the information you are requesting and
          the type of request you are making, verifying your name, email
          address, and date of birth. You are also permitted to designate an
          authorized agent to submit certain requests on your behalf. In order
          for an authorized agent to be verified, you must provide the
          authorized agent with signed, written permission to make such request
          or a power of attorney. We may also follow up with you to verify your
          identity before processing the authorized agent’s request.
        </p>
        <p>
          Additionally, exercising your deletion right may mean that you may no
          longer be able to use the Service.
        </p>
        <p>
          If you would like further information regarding your legal rights
          under California law or would like to exercise any of them, please
          contact us via our web form here.
        </p>
        <p>9.4 Notice of Right to Opt Out of Sale of Personal Information</p>
        <p>
          California residents may opt out of the “sale” of their personal
          information. California law broadly defines “sale” in a way that
          includes when we share your information with third parties, such as
          sports leagues or teams, to provide you with offers and promotions
          that we believe may be of interest to you. It also may include
          allowing third parties to receive certain information, such as cookies
          IP address and/ or browsing behavior, to deliver targeted advertising
          on the Services or other services. Advertising, including targeted
          advertising, enables us to provide you certain content for free and
          allows us to provide you offers relevant to you.
        </p>
        <p>
          Depending on what Services you use, we may provide the following
          categories of information to third parties for these purposes:
        </p>
        <p>
          <ul>
            <li>
              For online targeted advertising purposes: demographic and
              statistical information, user-generated content, device
              information and identifiers, connection and usage data,
              geolocation, and social media information.
            </li>
            <li>
              For sharing with third parties to send you relevant offers and
              promotions: contact and account registration information;
              demographic and statistical information, user-generated content,
              and geolocation.
            </li>
          </ul>
        </p>
        <p>
          If you would like to opt out of our use of your information for such
          purposes that are considered a “sale” under California law, you may do
          so as outlined on the following page: Do Not Sell My Personal
          Information. You can also submit a sale opt-out request by emailing us
          at{" "}
          <a href="mailto:support@getsquareup.com">support@getsquareup.com</a>.
          Please note that we do not knowingly sell the personal information of
          minors under 16 years of age without legally-required affirmative
          authorization.
        </p>
        <h3>10. Changes and updates</h3>
        <p>
          10.1 This Privacy Policy may be revised periodically and this will be
          reflected by the "Last update posted" date above. Please revisit this
          page to stay aware of any changes.If a revision to this Privacy
          Policy, in our sole discretion, is material, we will notify you by
          contacting you through the email address associated with your account.
        </p>
        <p>
          10.2 Contact Information: Please contact SquareUp with any questions
          or comments about this Privacy Policy by emailing
          <a href="mailto:support@getsquareup.com">support@getsquareup.com</a>.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
